import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import RegionOverview from "./components/region/overview/RegionOverview";
export const RegionModal = () => {
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);
  const [regionId, setRegionId] = useState(null);
  const [geoContext, setGeocontext] = useState(null);
  const [zIndex, setZIndex] = useState(111111001);

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const onClose = () => {
    setModalOpen(false);
    //setPlaceId(null);
    window.urbnups.maxZIndex = (window.urbnups?.maxZIndex || 111111001) - 1;
  };

  const handlePopState = (event) => {
    //alert("handlePopState");
    setModalOpen(false);
  };

  useEffect(() => {
    window.addEventListener("message", (ev) => {
      const event = ev.data;

      if (event.type === "urbnups-show-region") {
        console.log("urbnups-show-region", event.data);

        var newZIndex = (window.urbnups.maxZIndex || 111111001) + 1;
        setZIndex(newZIndex);
        window.urbnups.maxZIndex = newZIndex;
        window.history.pushState({ modalOpen: true }, "", window.location.href);
        setRegionId(event.data?.key || event.data?.regionId || null);
        setGeocontext(event.data?.geoContext || null);
        setModalOpen(true);
        window.addEventListener("popstate", handlePopState);
      }
    });

    return () => {
      window.removeEventListener("message", () => {});
    };
  }, []);

  return (
    <Dialog
      open={modalOpen}
      //     fullScreen={!isDesktop}

      sx={{ zIndex: zIndex }}
      maxWidth="md"
      fullScreen={!isDesktop}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogContent
        sx={{
          p: 0,
          //width: isDesktop ? "900px" : "100%",
          //height: isDesktop ? "900px" : "100%",
          width: isDesktop ? "900px" : "100%",
          flexGrow: 1,
        }}
      >
        {" "}
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            position: "absolute",

            p: 1,
            zIndex: 100,
            //align vertical
          }}
        >
          {onClose && (
            <Box>
              <IconButton className="urbnups-backButton" onClick={onClose}>
                <ArrowBackIosNewIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        {modalOpen && (
          <RegionOverview geocontext={geoContext} regionid={regionId} />
        )}
      </DialogContent>
    </Dialog>
  );
};
