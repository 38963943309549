"use client";
import { Box, Button } from "@mui/material";
import { Img } from "react-image";


const MomentMapButton = ({ moment, title = "Show Map" }) => {

  const matchesM = false
  const showLocation = () => {
   

    if (moment?.location?.lat != null && moment?.location?.long != null) {
      var link =
        "https://www.google.com/maps/search/?api=1&query=" +
        moment?.location.lat +
        "," +
        moment.location.long;
      if (matchesM) window.open(link, "_blank");
      else window.open(link, "_self");
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 2,
      
      }}
    >
      <Button
        startIcon={
          <Img
            src="https://static.urbnups.app/webapp/svg/urbnups_Icon_Navi_AhMUXyXYY.svg?updatedAt=1701432343400"
            width={20}
            height={20}
            alt="navigation icon"
          />
        }
        onClick={() => {
          showLocation();
        }}
        variant="contained"
        color="primary"
        sx={{
          color: "white",
          boxShadow: "none",
          marginTop: 1,
          marginLeft:1,
          borderColor: "white",
          backgroundColor: "rgba(0,0,0,0.7)",
        }}
      >
        {title}
      </Button>
    </Box>
  );
};

export default MomentMapButton;
