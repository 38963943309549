import { Box, Link, Typography } from "@mui/material";
import { Img } from "react-image";
import TranslationHelper from "../../utils/TranslationHelper";
import ResizeHelper from "../../utils/ResizeHelper";
const getThumbnail = (moment) => {
  if (moment.thumbnail !== null) {
    return ResizeHelper.resizeImage(moment.thumbnail, 600);
  } else if (moment.images !== null && moment.images.length > 0) {
    return ResizeHelper.resizeImage(moment.images[0].url, 600);
  } else if (moment.videos !== null && moment.videos.length > 0) {
    return (
      moment?.videos[0]?.url
        .substring(0, moment?.videos[0]?.url.indexOf(".mp4") + 4)
        .replace(
          "https://firebasestorage.googleapis.com:443/v0/b/urbnups.appspot.com/o/spots%2F",
          "https://static.urbnups.app/spots/"
        ) + "/ik-thumbnail.jpg"
    );
  } else {
    return null;
  }
};

const SingleMomentSlider = ({ moment, onShowMoment = () => {} }) => {
  const momentTranslations = TranslationHelper.fetchMomentTranslations(
    moment,
    window.urbnups.language
  );

  const thumbnail = getThumbnail(moment);

  return (
    <Box
      onClick={() => onShowMoment(moment)}
    
      sx={{
        cursor: "pointer",
        height: 160,
    flexShrink: 0,
    position: "relative",
        width:{
          xs:"75%",
          sm:"50%",
          md:"33.333%",
          lg:"25%"
        }
      }}
      key={moment.key}
    >
      {thumbnail !== null && (
        <Img
          src={`${thumbnail}`}
          alt={moment.name || ""}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
          className="moment-thumbnail"
        />
      )}
      {thumbnail === null && (
        <Box
          style={{
            position: "relative",
            flex: 1,
            width: "100%",

            width: "100%",
            backgroundColor: "#1a1a1b",
          }}
        />
      )}
      <Box className="moment-name-container">
        <Typography className="moment-name" variant="h6" sx={{ padding: 1 }}>
          {momentTranslations.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default SingleMomentSlider;
