import { Box, Link, Typography } from "@mui/material";
import { Img } from "react-image";
import TranslationHelper from "../../utils/TranslationHelper";
import ResizeHelper from "../../utils/ResizeHelper";
import MomentEstablishment from "./MomentEstablishment";
const getThumbnail = (moment) => {
  if (moment.thumbnail !== null) {
    return ResizeHelper.resizeImage(moment.thumbnail, 900);
  } else if (moment.images !== null && moment.images.length > 0) {
    return ResizeHelper.resizeImage(moment.images[0].url, 900);
  } else if (moment.videos !== null && moment.videos.length > 0) {
    return (
      moment?.videos[0]?.url
        .substring(0, moment?.videos[0]?.url.indexOf(".mp4") + 4)
        .replace(
          "https://firebasestorage.googleapis.com:443/v0/b/urbnups.appspot.com/o/spots%2F",
          "https://static.urbnups.app/spots/"
        ) + "/ik-thumbnail.jpg"
    );
  } else {
    return null;
  }
};

const onShowMoment = (moment) => {
  window.postMessage(
    {
      type: "urbnups-show-moment",
      data: moment,
    },
    "*"
  );
};

const SingleMomentFeed = ({ moment, language = "de" }) => {
  const thumbnail = getThumbnail(moment);

  return (
    <Box
      key={moment.key}
      sx={{
        cursor: "pointer",
      }}
      onClick={() => {
        onShowMoment(moment);
      }}
    >
      <Box
        sx={{
          position: "relative",
          flex: 1,
          width: "100%",

          overflow: "hidden",
        }}
      >
        {thumbnail !== null && (
          <Img
            src={`${thumbnail}`}
            alt={moment.name || ""}
            quality={60}
            style={{
              objectFit: "cover",
              objectPosition: "center",

              width: "100%",
              maxHeight: 400,
              borderRadius: 8,
            }}
            loader={
              <Box
                style={{
                  position: "relative",
                  flex: 1,
                  width: "100%",
                  height: 200,
                  backgroundColor: "#1a1a1b",
                  borderRadius: 8,
                }}
              />

            }
          />
        )}
        {thumbnail === null && (
          <Box
            style={{
              position: "relative",
              flex: 1,
              width: "100%",
              height: 200,
              backgroundColor: "#1a1a1b",
              borderRadius: 8,
            }}
          />
        )}
        <Box sx={{
          pl:0.5,
        }}>
        <Typography variant="h6" component={"h2"} sx={{ pt: 1 }}>
          {moment.name}
        </Typography>
     
        <Typography
          variant="subtitle2"
          component={"span"}
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            hyphens: "auto",
            overflow: "hidden",
            WebkitLineClamp: "2",
          }}
        >
          {moment.description}
        </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleMomentFeed;
