import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Box,
  Button,
  IconButton,
  Slide,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useRef, useState } from "react";
import AnswerValueRenderer from "./AnswerValueRenderer";
import Question from "./Question"; // Import the new Question component

const tripPreferencesQuestions = [
  {
    question: "Wann möchtest du reisen?",
    label: "Wann",
    answerType: "daterange",
    key: "tripDate",
    required: true,
  },
  {
    question: "Wie heißt du?",
    label: "Name",
    key: "username",
    answerType: "input",
    required: false,
  },
  {
    question: "Wer reißt mit?",
    label: "Wer",
    key: "travelers",
    answerType: "multiplechoice",
    allowCustomInput: true,
    required: false,
    predefinedAnswers: [
      {
        text: "Nur ich",
        thumbnail:
          "https://static.urbnups.app/proxy/tr:w-600/https://dam.destination.one/211647/291cd7dcc754566b0f63979c8c4c6e29b7af357a92d1bfb862bf491f29c25b56/herzog-august-bibliothek.jpg",
      },
      {
        text: "Mit Familie",
        thumbnail:
          "https://static.urbnups.app/proxy/tr:w-600/https://dam.destination.one/211620/b9c56bbe381e6867f3e817e6495c619ecdaa193d58faa01bf040c2553834354e/hauptkirche-bmv.jpg",
      },
      {
        text: "Mit Freunden",
        thumbnail:
          "https://static.urbnups.app/proxy/tr:w-600/https://dam.destination.one/211647/291cd7dcc754566b0f63979c8c4c6e29b7af357a92d1bfb862bf491f29c25b56/herzog-august-bibliothek.jpg",
      },
    ],
  },
  // Add more questions as needed
];

const QuestionSlider = () => {
  const [questions, setQuestions] = useState(tripPreferencesQuestions);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [customInput, setCustomInput] = useState("");
  const scrollRef = useRef(null);
  const [answers, setAnswers] = useState([]);
  const [noMoreQuestions, setNoMoreQuestions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const submitUpdatedProfile = () => {
    // Submit the answers to the server
    console.log("Submitting answers", answers);
  };

  const submitAnswer = async (question, answer) => {


    const newAnswers = answers.map((a) =>
      a.label === question.label ? { ...question, value: answer } : a
    );

    if (!newAnswers.some((a) => a.label === question.label)) {
      newAnswers.push({ ...question, value: answer });
    }

    setAnswers(newAnswers);

    // Check if this was the last question
    if (currentQuestionIndex === questions.length - 1) {
      await fetchMoreQuestions(newAnswers);
    } else {
      // Move to the next question
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const fetchMoreQuestions = async (newAnswers) => {
    setIsLoading(true);
    try {
      // Simulate API call (replace with your actual API call)
      const response = await fetch(
        "http://localhost:3000/widgets.api/region/OKSCkFyV8QeiRS5yWIvNz0zXwwa2/personalization?lang=de&partnerId=WXvCjfKEhGTEnMGPVCYxTUXWy0Q2&userId=db172df8-e5f6-4c80-bcd8-abc7966c1dfc&sessionId=9222f40a-a140-4cff-a2b3-d15eb3140a7b&categoryId=UraTDxxeh6URxEPR0SXc",
        {
          method: "POST",
          body: JSON.stringify({
            tripPreferences: newAnswers,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      console.log("Fetched more questions", data);
      if (data ) {

        setQuestions((prevQuestions) => [...prevQuestions, data]);
        setNoMoreQuestions(false);
        // Move to the next question
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      } else {
        setNoMoreQuestions(true);
      }
    } catch (error) {
      console.error("Error fetching more questions:", error);
      setNoMoreQuestions(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrev = () => {
    if (currentQuestionIndex > 0) {
      setNoMoreQuestions(false);
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  console.log(answers);

  return (
    <Box>
      <Box
        textAlign="center"
        sx={{
          width: "500px",
          minHeight: "400px",
          padding: "20px",
          borderRadius: "15px",
          textAlign: "center",
          rowGap: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {answers.map((answer, index) => (
          <Slide
            key={index}
            direction="up"
            in={true}
            mountOnEnter
            unmountOnExit
            timeout={500}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#FFF",
                padding: "15px",
                borderRadius: "10px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                border: "1px solid #e0e0e0",
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {answer.label}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 700 }}>
                <AnswerValueRenderer value={answer.value} />
              </Typography>
            </Box>
          </Slide>
        ))}

        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 4,
            }}
          >
            <CircularProgress />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Loading more questions...
            </Typography>
          </Box>
        ) : noMoreQuestions ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              textAlign: "left",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              pb: 2,
            }}
          >
            {currentQuestionIndex > 0 && (
              <IconButton onClick={handlePrev} sx={{ color: "#000" }}>
                <ArrowBackIosIcon />
              </IconButton>
            )}
            <Typography
              variant="h5"
              sx={{ marginBottom: "20px", textAlign: "center", width: "100%" }}
            >
              Keine weiteren Fragen
            </Typography>
          </Box>
        ) : (
          <Question
            currentQuestionIndex={currentQuestionIndex}
            hasPrev={currentQuestionIndex > 0}
            hasNext={currentQuestionIndex < questions.length - 1}
            questionData={questions[currentQuestionIndex]}
            questionsLength={questions.length}
            handlePrevQuestion={handlePrev}
            handleNextQuestion={submitAnswer}
            submitAnswer={submitAnswer}
            answers={answers}
          />
        )}
      </Box>
      <Button fullWidth variant="contained">
        Vorschläge anzeigen
      </Button>
    </Box>
  );
};

export default QuestionSlider;
