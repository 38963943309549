import { Box, CircularProgress } from "@mui/material";
import { useMemo, useState } from "react";
import { useFetch } from "use-http";
import RegionBanner from "../banner/RegionBanner";
import { RegionFeedModal } from "../feed/RegionFeedModal";

import { useDocumentTitle, useLocalStorage, useSessionStorage } from "@uidotdev/usehooks";
import { useTranslation } from "react-i18next";
import { PersonalizeRegionModal } from "../../personalization/PersonalizeRegionModal";
import RegionCategoriesSlider from "../categories/RegionCategoriesSlider";
import RegionChatCTA from "../feed/RegionChatCTA";
import RegionFeed from "../feed/RegionFeed";
const partnerId = window.urbnups.partnerId;

const language = window.urbnups.language;
const url = "http://localhost:4000/#/amoos?id=101"; // We can use 'window.location'

function getUrlParam(q) {
  var url = window.location.href;
  return (url.match(new RegExp("[?&]" + q + "=([^&]+)")) || [, null])[1];
}
const RegionOverview = ({
  regionid: regionIdParam = null,
  geocontext = null,
}) => {

  

  const regionid = regionIdParam || getUrlParam("regionid");
  const { t } = useTranslation();
  const userId = window.urbnups.userId;
  const sessionId = window.urbnups.sessionId;
  const [selectedCategory, setSelectedCategory] = useSessionStorage("destionation_"+regionid,{
    regionid: regionid,
    key: "explore",
    name: "Entdecken",
  });

  const {
    loading,
    get,
    error,
    data = { region: null, feed: [] },
  } = useFetch(
    `https://urbnups.com/widgets.api/region/${regionid}/overview?lang=${language}&partnerId=${partnerId}&userId=${userId}${
      sessionId ? `&sessionId=${sessionId}` : ""
    }`,

    [regionid, language, partnerId]
  );

  const [showRegionFeedModal, setShowRegionFeedModal] = useState(false);
  const [regionFeedModalCategory, setRegionFeedModalCategory] = useState(null);
 

  console.log("data", data);

  const regionName = useMemo(() => {
    var name = "";

    if (data?.region?.geoContext?.city != null) {
      name = data?.region?.geoContext?.city;
    } else if (data?.region?.geoContext?.region != null) {
      name = data?.region?.geoContext?.region;
    } else if (data?.region?.geoContext?.country != null) {
      name = data?.region?.geoContext?.country;
    }
    return name;
  }, [data?.region]);

  useDocumentTitle(regionName);

  if (data?.region == null) {
    return null;
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <RegionBanner region={data?.region} />
      <Box
        sx={{
          mt: 2,
        }}
      />
      <RegionCategoriesSlider
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        categories={data?.categories}
      />

      <Box
        sx={{
          mt: 2,
        }}
      />
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress />
        </Box>
      )}
      <RegionFeed
        region={data?.region}
        regionid={regionid}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        firstPageEndCTA={
          <RegionChatCTA
            headline={t("region_chat_CTA.headline")}
            subline={t("region_chat_CTA.subline", {
              regionName: regionName,
            })}
            placeholder="Aktivitäten bei Regen für Kinder"
            region={data?.region}
            questions={[
              `Welche Sehenswürdigkeiten sollte ich in ${regionName} nicht verpassen?`,
              `Was sind die besten Restaurants in ${regionName}?`,
              `Was sind die besten Aktivitäten in ${regionName} für Kinder?`,
              `Welche kulinarischen Highlights gibt es in ${regionName}?`,
            ]}
          />
        }
      />

      {showRegionFeedModal && (
        <RegionFeedModal
          categories={data?.categories}
          currentCategoryId={regionFeedModalCategory}
          setCurrentCategoryId={setRegionFeedModalCategory}
          regionid={regionid}
          modalOpen={showRegionFeedModal}
          onClose={() => {
            setShowRegionFeedModal(false);
          }}
        />
      )}
      <PersonalizeRegionModal regionid={regionid} />
    </Box>
  );
};

export default RegionOverview;
