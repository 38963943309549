import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, IconButton, Link, Typography } from "@mui/material";
import SingleMomentSlider from "../moment/SingleMomentSlider";

const MomentsSlider = ({
  loading = false,
  moments,
  headline,
  subline,
  showMore = false,
  moreLabel = "more",
  moreLink = null,
  onShowMoment = () => {},
}) => {
  return (
    <Box className="sliderContainer">
      <Box
        sx={{ display: "flex", alignContent: "center", alignItems: "center" }}
      >
        {headline && (
          <Link href={moreLink}>
            <Typography
              variant="h3"
              sx={{
                color: "black",
                whiteSpace: "normal",
                fontWeight: 600,
                ml: {
                  xs: 3,
                  md: 0,
                },
                mr: {
                  xs: 3,
                  md: 0,
                },
              }}
            >
              {headline}
            </Typography>
          </Link>
        )}

        {showMore && (
          <Box sx={{ display: "flex", marginLeft: "auto", pr: 1 }}>
            <IconButton
              href={moreLink}
              LinkComponent={Link}
              sx={{
                backgroundColor: "rgba(255,255,255,1)",
                borderRadius: 30,
                pl: 2,

                color: "white",
                //hover color
                "&:hover": {
                  backgroundColor: "#FF8243",
                  color: "white",
                },
              }}
            >
              <Typography
                sx={{
                  color: "#1c1c1c",
                }}
                variant="subtitle2"
              >
                {moreLabel}
              </Typography>
              <ArrowForwardIosIcon sx={{ color: "#1c1c1c", height: 15 }} />
            </IconButton>
          </Box>
        )}
      </Box>
      {subline != null && (
        <Typography
          variant="subtitle2"
          sx={{
            mt: 0.2,
            lineClamp: 1,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            hyphens: "auto",
            overflow: "hidden",
            WebkitLineClamp: "2",
            ml: {
              xs: 3,
              md: 0,
            },
            mr: {
              xs: 3,
              md: 0,
            },
          }}
        >
          {subline}
        </Typography>
      )}

      <Box sx={{ mt: 1 }}>
        <Box
        className="moments-slider"
          sx={{
            display: "flex",
            flexDirection: "row",
            scrollSnapAlign: "center",
            scrollSnapType: "x mandatory",
            overflowX: "auto",
            overflowY: "hidden",
            whiteSpace: "nowrap",
            scrollBehavior: "smooth",
            WebkitOverflowScrolling: "touch",
            "&::-webkit-scrollbar": {
              display: "none",
            },
           
            width: "100%",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            columnGap: 1,
          }}
        >
          {!loading &&
            moments != null &&
            moments.map((moment, index) => (
              <SingleMomentSlider
                onShowMoment={onShowMoment}
                key={moment?.key}
                moment={moment}
              />
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default MomentsSlider;
