
const TrackingHelper = {
  trackPlaceView: (place) => {
    window.urbnupsanalytics.track("establishment_view", {
      name: place?.name,
      googlePlaceId: place?.googlePlaceId,
      place_id: place?.googlePlaceId,
      location: place?.location,
      source:"widget",
      partnerId:window.urbnups.partnerId,
      language: window.urbnups.language,

    });
  },
  trackTourView: (googlePlaceId) => {
    
  },
  trackDestinationView: (region) => {
    window.urbnupsanalytics.track("destination_view", {
      
      regionId: region.key,
      geoContext:region?.geoContext,
      location: region?.location,
      name: region?.name,
      source:"widget",
      partnerId:window.urbnups.partnerId,
      language: window.urbnups.language,

    });
    
  },
  trackEventView: (googlePlaceId) => {
    
   
  },


  trackChatOpen:()=>{

  },
  trackChatClose:()=>{

  },
  trackRegionChatCTAInputMessage:()=>{

  },
  trackPlaceChatCTAInputMessage:()=>{

  },
  
 
};
export default TrackingHelper;
