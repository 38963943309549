import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "use-http";
import CategorySlider from "../../categories/CategorySlider";
import RegionPOIMap from "../../maps/RegionPOIMap";
import SingleMomentFeed from "../../moment/SingleMomentFeed";
export const RegionFeedModal = ({
  regionid,
  currentCategoryId,
  setCurrentCategoryId,
  modalOpen,
  onClose,
  categories = [],
}) => {
  const [lastMomentId, setLastMomentId] = useState(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const partnerId = window.urbnups.partnerId;
  const language = window.urbnups.language;
  const { t } = useTranslation();
  const [viewType, setViewType] = useState("list");

  const {
    loading,
    get,
    error,
    data = { moments: [], categoryId: currentCategoryId },
  } = useFetch(
    `https://urbnups.com/widgets.api/region/${regionid}/moments?lang=${language}&partnerId=${partnerId}&regionid=${regionid}${
      currentCategoryId != null ? `&categoryId=${currentCategoryId}` : ""
    }`,
    {
      onNewData: (currData, newData) => {
        console.log("onNewData", currData, newData);

        if (currData?.categoryId != currentCategoryId) {
          return {
            moments: newData.moments,
            hasMore: newData.hasMore,
            lastMomentId: newData.lastMomentId,
            categoryId: currentCategoryId,
          };
        } else {
          var previousMoments = currData?.moments || [];

          return {
            moments: [...previousMoments, ...newData.moments],
            hasMore: newData.hasMore,
            lastMomentId: newData.lastMomentId,
            categoryId: currentCategoryId,
          };
        }
      }, // appends newly fetched todos
      perPage: 0, // stops making more requests if last todos fetched < 15
    },
    [regionid, language, partnerId, currentCategoryId]
  );

  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
  const observerRef = useRef(null);
  console.log("data", data);

  const fetchNextPage = async () => {
    console.log("fetchNextPage " + data.lastMomentId);
    if (isFetchingNextPage) {
      return;
    }

    setIsFetchingNextPage(true);
    await get(
      `https://urbnups.com/widgets.api/region/${regionid}/moments?lang=${language}&partnerId=${partnerId}&regionid=${regionid}${
        currentCategoryId != null ? `&categoryId=${currentCategoryId}` : ""
      }${data.lastMomentId != null ? `&lastMomentId=${data.lastMomentId}` : ""}`
    );
    setIsFetchingNextPage(false);
    // setIsFetchingNextPage(true);
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      console.log("entries", entries);
      if (
        entries.some((entry) => entry.isIntersecting) &&
        data?.hasMore &&
        !isFetchingNextPage
      ) {
        fetchNextPage();
      }
    });
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [observerRef, data, isFetchingNextPage]);
  return (
    <Dialog
      open={modalOpen}
      fullScreen={!isDesktop}
      maxWidth="md"
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogContent
        sx={{
          p: 0,
          width: isDesktop ? "900px" : "100%",
          height: isDesktop ? "900px" : "100%",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",

            p: 1,
            zIndex: 100,
            //align vertical
          }}
        >
          {onClose && (
            <Box>
              <IconButton className="urbnups-backButton" onClick={onClose}>
                <ArrowBackIosNewIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box sx={viewType == "map"?{
          position:"absolute",
          zIndex: 100,
          mt:2,
        }:{}}>
        <CategorySlider
          currentCategoryId={currentCategoryId}
          setCurrentCategoryId={setCurrentCategoryId}
          categories={categories}
        />
        </Box>
        <>
          {error && "Error!"}

          {loading && data && data.moments.length === 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          )}

          {!loading && data && data.moments.length === 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <Typography variant="h6">{t("region_feed.noData")}</Typography>
            </Box>
          )}
          {data && data.moments != null && data.moments.length > 0 && (
            <>
            {viewType == "list" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                gap: 4,
              }}
            >
              {data?.moments.map((moment) => (
                <SingleMomentFeed key={moment.key} moment={moment} />
              ))}
              <Box
                ref={observerRef}
                sx={{ display: "flex", justifyContent: "center", py: 2 }}
              ></Box>
              {loading ||
                (isFetchingNextPage && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress className="urbnups-loadingindicator" />
                  </Box>
                ))}
            </Box>
            )}
            {viewType == "map" && (
              <RegionPOIMap pois={data?.moments} />
            )}
</>
          )}
        </>
        <ToggleButtonGroup
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
          color="primary"
          value={viewType}
          exclusive
          onChange={(event, newValue) => {
            setViewType(newValue);
          }}
          aria-label="Ansicht"
        >
          <ToggleButton value="list">Liste</ToggleButton>
          <ToggleButton value="map">Karte</ToggleButton>
        </ToggleButtonGroup>
      </DialogContent>
    </Dialog>
  );
};
