import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useFetch } from "use-http";
import POISlider from "../../slider/POISlider";
import { RegionFeedModal } from "../feed/RegionFeedModal";

import { useDocumentTitle } from "@uidotdev/usehooks";
import RegionPersonalizeCTA from "../../personalization/RegionPersonalizeCTA";
import SinglePOISlider from "./components/SinglePOI";
import { useTranslation } from "react-i18next";
import SingleDatahubEvent from "./components/SingleDatahubEvent";
import SingleDatahubTour from "./components/SingleDatahubTour";
import SingleDatahubHotel from "./components/SingleDatahubHotel";
import TrackingHelper from "../../../utils/TrackingHelper";
const partnerId = window.urbnups.partnerId;

const language = window.urbnups.language;

const onShowEvent = (event) => {
  console.log("onShowEvent", event);
  window.postMessage(
    {
      type: "urbnups-openinternalurl",
      data: {
        url: event.fullUrl,
      },
    },
    "*"
  );
};
const onShowPOI = (poi) => {
  console.log("onShowPOI", poi);
  window.postMessage(
    {
      type: "urbnups-show-place",
      data: poi,
    },
    "*"
  );
};
const onShowTour = (tour) => {
  console.log("onShowTour", tour);
  window.postMessage(
    {
      type: "urbnups-openinternalurl",
      data: {
        url: tour?.fullUrl,
      },
    },
    "*"
  );
};
const onShowHotel = (hotel) => {
  console.log("onShowHotel", hotel);
  window.postMessage(
    {
      type: "urbnups-openinternalurl",
      data: {
        url: hotel?.fullUrl,
      },
    },
    "*"
  );
};
const RegionFeed = ({
  region,
  regionid,
  selectedCategory,
  setSelectedCategory,
  firstPageEndCTA = null,
}) => {
  const userId = window.urbnups.userId;
  const sessionId = window.urbnups.sessionId;
  const limit = 12;
  const [offset, setOffset] = useState(0);
  const { t } = useTranslation();

  const {
    loading,
    get,
    error,
    data = { region: null, feed: [] },
  } = useFetch(
    `https://urbnups.com/widgets.api/region/${regionid}/feed?lang=${language}&partnerId=${partnerId}&userId=${userId}${
      sessionId ? `&sessionId=${sessionId}&limit=${limit}&offset=${offset}` : ""
    }${
      selectedCategory
        ? `&categoryId=${selectedCategory.key || "explore"}&categoryType=${
            selectedCategory.type
          }`
        : ""
    }`,

    {
      onNewData: (currData, newData) => {
        console.log("onNewData", currData, newData);
        var previous = currData?.feed || [];
        var newFeed = newData.feed || [];
        if (currData?.category?.key != selectedCategory?.key) {
          previous = [];
        }

        //remove duplicates
        newFeed = newFeed.filter(
          (item) =>
            !previous.some((prev) => prev.googlePlaceId == item.googlePlaceId)
        );
        var feed = [];
        if (previous.length > 0) {
          feed = [...previous, ...newFeed];
        } else {
          feed = [
            ...newFeed,
            {
              itemType: "regionChatCta",
            },
          ];
        }
        console.log("feed", feed);
        return {
          feed: feed,
          hasMore: currData?.hasMore || false,
          category: selectedCategory,
        };
      }, // appends newly fetched todos
      perPage: 0, // stops making more requests if last todos fetched < 15
    },
    [regionid, language, partnerId, selectedCategory, limit, offset]
  );
  useDocumentTitle(region?.name);

  const [showRegionFeedModal, setShowRegionFeedModal] = useState(false);
  const [regionFeedModalCategory, setRegionFeedModalCategory] = useState(null);
  const observerRef = useRef(null);
  const hasMore = data?.hasMore;
  console.log(loading, error, hasMore);
  const fetchNextPage = async () => {
    // alert("fetchNextPage");
    if (!loading) setOffset(offset + limit);
  };

  useEffect(() => {
    //reset offset when region or category changes
    TrackingHelper.trackDestinationView(region);
    return () => {
      setOffset(0);
    };
  }, [region, selectedCategory]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        rowGap: 3,
      }}
    >
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {
        //show empty state if no data
        !loading && data?.feed.length <= 1 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              className="urbnups-feed-nodata"
              sx={{ textAlign: "center" }}
            >
              {t("region_feed.noData")}
            </Typography>
          </Box>
        )
      }
      {data?.feed.length > 0 && (
        <Box
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            p: 1,
            gap: 2,
          }}
        >
          {data?.feed != null &&
            data?.feed.length > 0 &&
            data?.feed.map((item, index) => {
              if (item?.itemType == "poislider")
                return (
                  <Box
                    key={item.key}
                    sx={{
                      gridColumn: "1 / -1",
                    }}
                  >
                    <POISlider
                      pois={item?.pois}
                      headline={item.headline}
                      subline={item.subline}
                      id={item.key}
                      onShowPOI={(poi) => {
                        console.log("onShowPOI", poi);
                        window.postMessage(
                          {
                            type: "urbnups-show-place",
                            data: poi,
                          },
                          "*"
                        );
                      }}
                      showMore={item?.categoryId != null}
                      moreLabel="Mehr"
                      onMore={() => {
                        /*
                      setShowRegionFeedModal(true);
                      setRegionFeedModalCategory(null);
                      */
                        if (item?.categoryId != null) {
                          setSelectedCategory({
                            categoryId: item?.categoryId,
                            categoryType: item?.categoryType,
                          });

                          //window scroll to top smooth
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }
                      }}
                    />
                  </Box>
                );
              if (item?.itemType == "poi") {
                return (
                  <SinglePOISlider
                    onShowPOI={onShowPOI}
                    key={item?.key}
                    poi={item}
                  />
                );
              }
              if (item?.itemType == "datahub_event") {
                return (
                  <SingleDatahubEvent
                    onShowEvent={onShowEvent}
                    key={item?.key}
                    event={item}
                  />
                );
              }
              if (item?.itemType == "datahub_tour") {
                return (
                  <SingleDatahubTour
                    onShowTour={onShowTour}
                    key={item?.key}
                    tour={item}
                  />
                );
              }
              if (item?.itemType == "datahub_hotel") {
                return (
                  <SingleDatahubHotel
                    onShowHotel={onShowHotel}
                    key={item?.key}
                    hotel={item}
                  />
                );
              }
              if (item?.itemType == "headline") {
                return (
                  <Box
                    key={item.key}
                    sx={{
                      gridColumn: "1 / -1",
                    }}
                  >
                    <Typography className="region-feed headline">
                      {item.headline}
                    </Typography>
                    {item.subline && (
                      <Typography className="region-feed subline">
                        {item.subline}
                      </Typography>
                    )}
                  </Box>
                );
              }
              if (item?.itemType == "personalizecta") {
                return <RegionPersonalizeCTA />;
              }
              if (item?.itemType == "regionChatCta") {
                return (
                  <Box
                    key={item.key}
                    sx={{
                      gridColumn: "1 / -1",
                    }}
                  >
                    {firstPageEndCTA}
                  </Box>
                );
              }
            })}
          {!loading && hasMore && (
            <Box
              sx={{
                gridColumn: "1 / -1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 1,
                mb: 2,
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  fetchNextPage();
                }}
              >
                {t("region_feed.loadMore")}
              </Button>
            </Box>
          )}

          {loading && hasMore && (
            <Box
              sx={{
                gridColumn: "1 / -1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 1,
                mb: 2,
              }}
            >
              <CircularProgress className="urbnups-loadingindicator" />
            </Box>
          )}
        </Box>
      )}

      {showRegionFeedModal && (
        <RegionFeedModal
          categories={data?.categories}
          currentCategoryId={regionFeedModalCategory}
          setCurrentCategoryId={setRegionFeedModalCategory}
          regionid={regionid}
          modalOpen={showRegionFeedModal}
          onClose={() => {
            setShowRegionFeedModal(false);
          }}
        />
      )}
    </Box>
  );
};

export default RegionFeed;
