import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Slide, Typography } from "@mui/material";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import i18n from "../../i18n";
import ChatIframe from "./ChatIframe";

const ChatWidget = ({ logo }) => {
  const url = window.urbnups.chatUrl;
  const [isOpen, setIsOpen] = useState(false);
  const [showInfo, setShowInfo] = useLocalStorage(
    "urbnups_showChatCTAInfo",
    true
  );
  const userId = window.urbnups.userId;
  const sessionId = window.urbnups.sessionId;
  const [cta, setCta] = useState(null);
  const [chatLoaded, setChatLoaded] = useState(false);
  const [pendingMessages, setPendingMessages] = useState([]);
  const [zIndex, setZIndex] = useState(111111001);

  useEffect(() => {
    if (i18n?.language != null) {
      const lang = i18n.language;
      if (window.urbnups.chatCTA[lang] != null)
        setCta(window.urbnups.chatCTA[lang]);
      else if (window.urbnups.chatCTA["en"] != null)
        setCta(window.urbnups.chatCTA["en"]);
    }
  }, [i18n]);

  const toggleChat = () => {
    setIsOpen(!isOpen);
    setShowInfo(false);
  };

  const handleMessage = (ev) => {
    const event = ev.data;
    if (event.type === "urbnups-chat-hide") {
      setIsOpen(false);
      setZIndex(zIndex - 1);
    }
    if (event.type === "urbnups-chat-show") {
      setIsOpen(true);
      var newZIndex = (window.urbnups.maxZIndex || 111111001) + 1;
      setZIndex(newZIndex);
      window.urbnups.maxZIndex = newZIndex;
    }
    if (event.type === "urbnups-chat-sendMessage") {
      const messageData = {
        type: "urbnups-chat-submitMessage",
        data: event.data,
      };
      if (chatLoaded && window.urbnups.chatIframeRef) {
        window.urbnups.chatIframeRef.contentWindow.postMessage(
          messageData,
          "*"
        );
      } else {
        setPendingMessages((prevMessages) => [...prevMessages, messageData]);
      }
    }
    if (event.type === "urbnups-openexternal") {
      window.open(event.data.url, "_blank");
    }
    if (event.type === "urbnups-openinternalurl") {
      window.open(event.data.url, "_self");
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  useEffect(() => {
    if (
      chatLoaded &&
      pendingMessages.length > 0 &&
      window.urbnups.chatIframeRef
    ) {
      console.log("Sending pending messages", pendingMessages);
      setTimeout(() => {
        pendingMessages.forEach((messageData) => {
          window.urbnups.chatIframeRef.contentWindow.postMessage(
            messageData,
            "*"
          );
        });
        setPendingMessages([]);
      }, 1000);
    }
  }, [chatLoaded, pendingMessages]);

  return (
    <Box>
      {!isOpen && (
        <Box
          sx={{
            position: "fixed",
            bottom: 12,
            right: 12,
            zIndex: 1000,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {cta != null && (
            <Box
              sx={{
                backgroundColor: "#f0f0f0",
                borderRadius: 4,
                padding: 2,
                position: "absolute",
                display: showInfo !== false ? "block" : "none",
                bottom: 30,
                right: 50,
                width: {
                  xs: "200px",
                  md: "250px",
                },
                borderRadius: "16px",
                wordBreak: "break-word",
                border: "solid 1px #eee",
                boxShadow: "0 3px 15px #00000026",
              }}
            >
              <CloseIcon
                onClick={() => {
                  setShowInfo(false);
                }}
                sx={{
                  color: "black",
                  position: "absolute",
                  right: 5,
                  top: 5,
                  cursor: "pointer",
                }}
              />
              <Typography variant="body1" sx={{ color: "black" }}>
                {cta}
              </Typography>
            </Box>
          )}

          <IconButton
            color="secondary"
            onClick={toggleChat}
            sx={{ position: "absolute", bottom: 0, right: 0, p: 1 }}
          >
            <img
              alt="Chatbot Logo"
              src={logo}
              style={{ width: 60, height: 60 }}
            />
          </IconButton>
        </Box>
      )}

      <Modal
        open={isOpen}
        onClose={toggleChat}
        keepMounted
        sx={{ zIndex: zIndex }}
      >
        <Slide direction="up" in={isOpen} mountOnEnter>
          <Box
            sx={{
              position: "absolute",
              bottom: {
                xs: 0,
                md: 12,
              },
              right: {
                xs: 0,
                md: 12,
              },
              /*
              maxHeight: {
                xs: "100vh",
                md: "80vh",
              },
              */
              width: {
                xs: "100%",
                md: "450px",
              },
              height: {
                xs: "100%",
                md: "600px",
              },
              background: "white",
              borderRadius: {
                xs: 0,
                md: 2,
              },
              boxShadow: {
                xs: undefined,
                md: "0 3px 10px rgb(0 0 0 / 0.2)",
              },
              overflow: "hidden",
            }}
          >
            <ChatIframe
              setChatLoaded={setChatLoaded}
              url={url}
              userId={userId}
              sessionId={sessionId}
            />
          </Box>
        </Slide>
      </Modal>
    </Box>
  );
};

export default ChatWidget;
