import React from "react";
import { format } from "date-fns"; // For formatting dates

const AnswerValueRenderer = ({ value }) => {
  const isDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime()); // Check if the string is a valid date
  };

  const renderValue = () => {
    // Detect if it's a date range object with startDate and endDate keys
    if (typeof value === "object" && value.startDate && value.endDate) {
      const { startDate, endDate } = value;
      return `${format(new Date(startDate), "dd.MM.yyyy")} - ${format(new Date(endDate), "dd.MM.yyyy")}`;
    }

    // Detect if it's a multiple choice (array of strings)
    if (Array.isArray(value)) {
      return value.join(", ");
    }

    // Detect if it's a simple string or number
    if (typeof value === "string" || typeof value === "number") {
      return value;
    }

    return null; // Fallback for any other case
  };

  return <>{renderValue()}</>;
};

export default AnswerValueRenderer;