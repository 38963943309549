"use client";
import { Box } from "@mui/material";
import {
  ColorScheme,
  LoadPriority,
  Map,
  Marker,
  PointOfInterestCategory
} from "mapkit-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PlaceMiniMapShowMapButton from "./PlaceMiniMapShowMapButton";
import { ca } from "date-fns/locale";

function PlaceMiniMap({  place=null}) {

  const { t } = useTranslation();

  const initialRegion = useMemo(
    () => ({
      centerLatitude: place.location?.lat,
      centerLongitude: place.location?.long,
      latitudeDelta: 0.01,
      longitudeDelta: 0.01,
    }),
    [place]
  );




  return (
    <Box sx={{ overflow: "hidden", height: "300px", width: "100%", position: "relative" ,
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
    }}>
    <Box
      sx={{
      
        overflow: "hidden",
        height: "300px",
        width:{
          xs: "calc(100% - 16px)",
          md:"100%"
        },
        position: "relative",
        borderRadius:{
          xs: "8px",
          md: "0px",
        },
      }}
    >
    <Map
        colorScheme={ColorScheme.Light}
        loadPriority={LoadPriority.PointsOfInterest}
        showsUserLocationControl={true}
        showsPointsOfInterest={true}
        showsUserLocation={false}
        includedPOICategories={[
          PointOfInterestCategory.Park,
          PointOfInterestCategory.AmusementPark,
          PointOfInterestCategory.Beach,
          PointOfInterestCategory.Campground,
          PointOfInterestCategory.Library,
          PointOfInterestCategory.Marina,
          PointOfInterestCategory.Museum,
          PointOfInterestCategory.NationalPark,
          PointOfInterestCategory.Stadium,
          PointOfInterestCategory.Theater,
          PointOfInterestCategory.Zoo,
        ]}
        showsMapTypeControl={false}
        initialRegion={initialRegion}
       // token="eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkI1WDRYMjYyR0gifQ.eyJpc3MiOiJMS0tBOFREQlZWIiwiaWF0IjoxNzE0MDI2NTk1LCJleHAiOjE3NDU1MzkyMDAsIm9yaWdpbiI6Imh0dHA6Ly9sb2NhbGhvc3Q6MzAwMCxodHRwOi8vbG9jYWxob3N0OjMwMDEsaHR0cDovL2xvY2FsaG9zdDozMDAzLGh0dHA6Ly9sb2NhbGhvc3Q6MzAwMixodHRwczovL3VyYm51cHMuY29tLGh0dHBzOi8vc3RhZ2luZy51cmJudXBzLmNvbSJ9._i6KsX4ZzL9RfM4Xj2uBEqkTGE4g43muAv9BUzu9Ur1HbLPITqjPanJ8FMXr6g-pCyvXfxp7IFyzzTLjUvr6Pg"
       //token="eyJraWQiOiI0Vlo3QzlVSzhKIiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJMS0tBOFREQlZWIiwiaWF0IjoxNzIzMDMyOTUxLCJvcmlnaW4iOiJlbnRkZWNrZS1yZWlzZWxhbmQtbmllZGVyc2FjaHNlbi5kZSJ9.5219vky_Un0ICKAk_C3zfM4NammF4OSLUuG44t1-3CGMPx22SlaaOjpTUq31qA5Yr4ifWoRGxRDVEIEGLhnHFA"
       token="eyJraWQiOiJGUVpWVEIzUVhRIiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJMS0tBOFREQlZWIiwiaWF0IjoxNzIzNjE4MjQ0LCJvcmlnaW4iOiIqLmVudGRlY2tlLXJlaXNlbGFuZC1uaWVkZXJzYWNoc2VuLmRlIn0.9ymtWaU35GfJul3UkCT7Xhhsck2c3OtsYiiSyTZA8xZfG4oCz-WzMnsoyh2-j8x4KtCo_bqKpyBxyGkkCrGwfg"
      >
        <Marker
          selected={false}
          title={ place.name}
          color="#FF8243"
          latitude={place.location?.lat}
          longitude={place.location?.long}
        />
        
    
      </Map>
      <PlaceMiniMapShowMapButton place={place} title={t("place.navigation")} />
     
      
    </Box>
    </Box>
  );
}
export default PlaceMiniMap;
