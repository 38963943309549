import { Box, Typography } from "@mui/material";
import { Img } from "react-image";
import ResizeHelper from "../../../../utils/ResizeHelper";
const SinglePOISlider = ({ poi,withinFeed=false, onShowPOI = () => {},scrollPosition }) => {
  return (
    <Box
      onClick={() => onShowPOI(poi)}
      sx={{
        cursor: 'pointer',
        overflow: 'hidden',
        flexShrink: 0,
        position: 'relative',
        borderBottomLeftRadius:"4px",
        borderBottomRightRadius:"4px",
        borderTopLeftRadius:"4px",
        borderTopRightRadius:"4px",
        border: "1px solid #dcdfe4",
      
        flexBasis: {
          xs: 240,
          sm: 240,
          md: 300,
          lg: 300,
        },
      }}
      key={poi.key}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: {
            xs: 200,
            sm: 200,
            md: 200,
            lg: 200,
          },
        }}
      >
        {poi?.thumbnail != null && (
          
          <Img
          placeholder
              className="moment-thumbnail"
          src={ResizeHelper.resizeImage(poi?.thumbnail, 600)}
          alt={poi.name || ""}
          scrollPosition={scrollPosition}
          style={{
            width: "100%",
         
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}/>
        )}
        {poi?.thumbnail == null && (
          <Box
          className="urbnups-poi-thumbnail-placeholder"
            style={{
              position: "relative",
              flex: 1,
              width: "100%",
              height: "100%",
              width: "100%",
              backgroundColor: "#1a1a1b",
            }}
          />
        )}
      </Box>
      <Box className="urbnups-singlepoislider-content">
        <Box className="urbnups-singlepoislider-content-text">
        <Typography
            component={"div"}
          className="urbnups-poi-slider-poi-name" >
      
          {poi.name}
        </Typography>
        <Typography
           component={"div"}
           className="urbnups-poi-slider-poi-teaser"
   
        >
          {poi.teaser}
        </Typography>
      </Box>
      </Box>
    </Box>
  );
};

export default SinglePOISlider;
