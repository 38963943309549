

import { Box, Typography } from "@mui/material";


import TranslationHelper from "../../utils/TranslationHelper";
const MomentLocation = ({ moment , language}) => {

  const country = TranslationHelper.formatLocationString(
    moment?.geoContexts,
    language,
    true,
    "country"
  );
  const city = TranslationHelper.formatLocationString(
    moment?.geoContexts,
    language,
    true,
    "city"
  );

  const region = TranslationHelper.formatLocationString(
    moment?.geoContexts,
    language,
    true,
    "region"
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        
        flex: 1,
        
      }}
    >
      {country && (
     
        <Typography
     variant="subtitle2"
     component={"span"}
        >
          {country}
        </Typography>
      )}
       {region && (
        <Typography
          variant="subtitle2"
          component={"span"}
        >&nbsp;/&nbsp;{region}
        </Typography>
      )}
       {city!=region && (
        <Typography
         variant="subtitle2"
         component={"span"}
        >&nbsp;/&nbsp;{city}
        </Typography>
      )}
   
    </Box>
  );
};

export default MomentLocation;
