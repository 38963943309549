import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SendIcon from "@mui/icons-material/Send";
import { Box, Button, Card, CardActionArea, CardContent, IconButton, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { addDays, startOfWeek, endOfWeek, addMonths, format, nextFriday, nextSunday } from 'date-fns'; // import necessary date-fns functions

const Question = ({
  questionData,
  handleNextQuestion,
  handlePrevQuestion,
  currentQuestionIndex,
  hasPrev,
  hasNext,
  submitAnswer,
  answers,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [customInput, setCustomInput] = useState("");

  // Find if there's an existing answer for the current question
  const existingAnswer = answers.find((a) => a.label === questionData.label);

  // Effect to load existing answers for the current question
  useEffect(() => {
    if (existingAnswer) {
      if (questionData.answerType === "multiplechoice") {
        // Prefill selected options if it's multiple choice
        setSelectedOptions(Array.isArray(existingAnswer.value) ? existingAnswer.value.filter((v) => typeof v === "string") : []);
        setCustomInput(Array.isArray(existingAnswer.value) ? existingAnswer.value.find((v) => typeof v === "string") || "" : "");
      }
      if (questionData.answerType === "daterange") {
        // Prefill start and end date
        setStartDate(existingAnswer.value.startDate || "");
        setEndDate(existingAnswer.value.endDate || "");
      }
      if (questionData.answerType === "input") {
        // Prefill custom input
        setCustomInput(existingAnswer.value || "");
      }
    }
  }, [existingAnswer, questionData.answerType]);

  const handleOptionSelect = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleCustomInputChange = (e) => {
    setCustomInput(e.target.value);
  };

  const handleSubmit = () => {
    if (questionData.answerType === "multiplechoice") {
      if (selectedOptions.length > 0 && customInput !== "") {
        submitAnswer(questionData, [...selectedOptions, customInput]);
      } else if (selectedOptions.length > 0) {
        submitAnswer(questionData, selectedOptions);
      } else if (customInput !== "") {
        submitAnswer(questionData, customInput);
      }
    }
    if (questionData.answerType === "input") {
      submitAnswer(questionData, customInput);
    }
    if (questionData.answerType === "daterange") {
      submitAnswer(questionData, { startDate, endDate });
    }

    // Clear the input fields after submitting
    setSelectedOptions([]);
    setCustomInput("");
    setStartDate("");
    setEndDate("");
  };

  const renderSubmitButton = () => (
    <Button
      variant="contained"
      color="primary"
      sx={{
        backgroundColor: "#4CAF50",
        borderRadius: "50%",
        height: "50px",
        width: "50px",
        minWidth: "unset",
      }}
      onClick={handleSubmit}
    >
      <AddIcon />
    </Button>
  );

  const renderMultipleChoice = () => {
    const hasThumbnail = questionData.predefinedAnswers[0]?.thumbnail; // Check if the first answer has a thumbnail
  
    return (
      <Box>
        {hasThumbnail ? (
          // Render as slider if the first element has a thumbnail
          <Box
            sx={{
              display: "flex",
              overflowX: "scroll",
              scrollBehavior: "smooth",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            {questionData.predefinedAnswers!=null&&questionData.predefinedAnswers.map((answer, index) => (
              <Box key={index} sx={{ flex: "0 0 40%", m: 1, minWidth: "40%" }}>
                <Card
                  sx={{
                    height: "300px",
                    position: "relative",
                    borderRadius: "12px",
                    overflow: "hidden",
                    backgroundImage: `url(${answer.thumbnail})`,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    border: selectedOptions.includes(answer.text) ? "3px solid #4CAF50" : "",
                  }}
                  onClick={() => handleOptionSelect(answer.text)}
                >
                  <CardActionArea
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" sx={{ color: "white", fontWeight: "bold" }}>
                        {answer.text}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
            ))}
          </Box>
        ) : (
          // Render as a list of buttons if no thumbnail is present
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2, // Add space between buttons
              mb:2,
            }}
          >
            {questionData.predefinedAnswers!=null&&questionData.predefinedAnswers.map((answer, index) => (
              <Button
                key={index}
                fullWidth
                variant="outlined"
                onClick={() => handleOptionSelect(answer.text)}
                sx={{
                    textAlign:"left",
                  borderRadius: "25px",
                  backgroundColor: selectedOptions.includes(answer.text) ? "#4CAF50" : "white",
                  color: selectedOptions.includes(answer.text) ? "white" : "black",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: "16px",
                  border: selectedOptions.includes(answer.text) ? "1px solid #4CAF50" : "1px solid black",
                }}
              >
                {answer.text}
              </Button>
            ))}
          </Box>
        )}
        {renderInputField()}
      </Box>
    );
  };

  const renderInputField = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        gap: 2,
      }}
    >
      <TextField variant="outlined" placeholder="Schreibe etwas..." fullWidth value={customInput} onChange={handleCustomInputChange} />
      {renderSubmitButton()}
    </Box>
  );

  const renderDateRangeInput = () => {

    // Helper function to format the date as "yyyy-MM-dd" for input elements
    const formatDateForInput = (date) => format(date, "yyyy-MM-dd");
  
    // Pre-calculate values for the buttons
    const handleSetNextWeekend = () => {
      const nextFri = nextFriday(new Date()); // Get next Friday
      const nextSun = nextSunday(new Date()); // Get next Sunday
      setStartDate(formatDateForInput(nextFri));
      setEndDate(formatDateForInput(nextSun));
    };
  
    const handleSetNextWeek = () => {
      const nextWeekStart = startOfWeek(addDays(new Date(), 7), { weekStartsOn: 1 }); // Next Monday
      const nextWeekEnd = endOfWeek(addDays(new Date(), 7), { weekStartsOn: 1 }); // Next Sunday
      setStartDate(formatDateForInput(nextWeekStart));
      setEndDate(formatDateForInput(nextWeekEnd));
    };
  
    const handleSetNextMonth = () => {
      const nextMonthStart = addMonths(new Date(), 1);
      const nextMonthEnd = endOfWeek(addDays(nextMonthStart, 30), { weekStartsOn: 1 }); // Roughly next month
      setStartDate(formatDateForInput(nextMonthStart));
      setEndDate(formatDateForInput(nextMonthEnd));
    };
  
    return (
      <Box>
       
  
        {/* Buttons to quickly set the date range */}
        <Box sx={{ mb: 2, display: 'flex', gap: 2, justifyContent: 'center' }}>
          <Button variant="outlined" onClick={handleSetNextWeekend}>
            Next Weekend
          </Button>
          <Button variant="outlined" onClick={handleSetNextWeek}>
            Next Week
          </Button>
          <Button variant="outlined" onClick={handleSetNextMonth}>
            Next Month
          </Button>
        </Box>
   {/* Date range input fields */}
   <TextField
          variant="outlined"
          type="date"
          placeholder="Startdatum"
          fullWidth
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          variant="outlined"
          type="date"
          placeholder="Enddatum"
          fullWidth
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        {/* Submit button */}
        {renderSubmitButton()}
      </Box>
    );
  };

  const renderQuestionContent = () => {
    switch (questionData.answerType) {
      case "multiplechoice":
        return renderMultipleChoice();
      case "input":
        return renderInputField();
      case "daterange":
        return renderDateRangeInput();
      default:
        return null;
    }
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          textAlign: "left",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 2,
        }}
      >
        <IconButton onClick={handlePrevQuestion} disabled={!hasPrev} sx={{ color: "#000" }}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h5" gutterBottom>
          {questionData.question||questionData?.response}
        </Typography>
        <IconButton onClick={handleNextQuestion} disabled={!hasNext} sx={{ color: "#000" }}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
      {renderQuestionContent()}
    </Box>
  );
};

export default Question;