const ResizeHelper = {
  normalizeSrc: (src) => {
    return src.startsWith("/") ? src.slice(1) : src;
  },
  getFilePath: function (url) {
    var folders = [
      "spots",
      "users",
      "users_banner",
      "regions",
      "spots_banner",
      "collections",
      "categories",
    ];
    var filePath = "";
    for (var i = 0; i < folders.length; i++) {
      var folder = folders[i];
      if (url.indexOf("/" + folder) > -1) {
        filePath = url.split(folder).pop();
      }
    }
    filePath = filePath.replace("%2F", "/");
    filePath = filePath.replace("%2F", "/");

    //remove alt and token query params
    if (filePath.indexOf("?") > -1) filePath = filePath.split("?")[0];
    return filePath;
  },
  resizeImage: function (orgUrl, width, height = null) {
    var newUrl = orgUrl;

    if (!orgUrl) return null;

    if (orgUrl.indexOf("https://static.urbnups.app") !== -1) {
      return orgUrl;
    }
    var url = decodeURI(orgUrl);

    var fileName = ResizeHelper.getFilePath(url);
    fileName = fileName.replace("%2F", "/");
    //check if filename starts with / and remove
    if (fileName.startsWith("/")) fileName = fileName.substr(1);

    if (url === null) return null;

    switch (true) {
      case url.indexOf("/spots") !== -1:
        newUrl = "https://static.urbnups.app/spots/" + fileName;
        break;
      case url.indexOf("/users") !== -1 && url.indexOf("/users_banner") === -1:
        newUrl = "https://static.urbnups.app/users/" + fileName;
        break;
      case url.indexOf("/users_banner") !== -1:
        newUrl = "https://static.urbnups.app/usersbanner/" + fileName;
        break;
      case url.indexOf("/regions") !== -1:
        newUrl = "https://static.urbnups.app/regions/" + fileName;
        break;

      case url.indexOf("/spots_banner") !== -1:
        newUrl = "https://static.urbnups.app/spots_banner/" + fileName;

        break;
      case url.indexOf("/collections") !== -1:
        newUrl = "https://static.urbnups.app/collections/" + fileName;

        break;
      case url.indexOf("/categories") !== -1:
        newUrl = "https://static.urbnups.app/categories/" + fileName;
        break;
      case url.indexOf("googleusercontent.com/places") !== -1:
        return `https://static.urbnups.app/proxy/tr:w-${width}/${ResizeHelper.normalizeSrc(
          url
        )}`;
        break;

       // case url.indexOf("dam.destination.one") !== -1:
       //   return `https://img.destination.one/remote/.jpg?mode=crop&scale=both&width=${width}&url=${encodeURIComponent(url)}`;
       //   break;
      default:
        console.log("no case matched for", url);
        return `https://static.urbnups.app/proxy/tr:w-${width}/${ResizeHelper.normalizeSrc(
          url
        )}`;
    }

    if (newUrl.indexOf("?") > -1) {
      newUrl += "&tr=w-" + width;
      if (height !== null) newUrl += "&tr=h-" + height;
    } else {
      newUrl += "?tr=w-" + width;
      if (height !== null) newUrl += "&tr=h-" + height;
    }

    return newUrl;
  },

  resizeVideo: function (url, width) {
    var newUrl = url;

    newUrl = newUrl
      .replace(
        "https://firebasestorage.googleapis.com:443/v0/b/urbnups.appspot.com/o/spots/",
        "https://static.urbnups.app/spots/"
      )
      .replace(
        "https://firebasestorage.googleapis.com/v0/b/urbnups.appspot.com/o/spots/",
        "https://static.urbnups.app/spots/"
      );

    if (newUrl.indexOf("?") > -1) {
      newUrl += "&tr=w-" + width;
    } else {
      newUrl += "?tr=w-" + width;
    }
    return newUrl;
  },
};
export default ResizeHelper;
