import { useTheme } from "@emotion/react";
import { Box, Button, IconButton, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { Img } from "react-image";
import CloseIcon from '@mui/icons-material/Close';
const PlaceMiniMapShowMapButton = ({ place, title = "Show Map" }) => {
  const theme = useTheme();
  const [showNavigationLinks, setShowNavigationLinks] = useState(false);

  const showLocation = () => {
    /*
    analytics?.track("show_spot_on_map", {
      spotId: place?.key,
      spot: moment,
    });
*/

    if(place?.navigationLinks !=null && place?.navigationLinks.length>0){
     
      setShowNavigationLinks(true);
      return

    }

    if (place?.location?.lat != null && place?.location?.long != null) {
      var link =
        "https://www.google.com/maps/search/?api=1&query=" +
        place?.location.lat +
        "," +
        place.location.long;
      if (true) window.open(link, "_blank");
      else window.open(link, "_self");
    }
  };

  if(showNavigationLinks){
    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          display: "flex",
          flexDirection: "column",
          flex: 1,
          backgroundColor: "rgba(0,0,0,0.7)",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          gap: 2,
        }}
      >
        <IconButton 
          onClick={() => {
            setShowNavigationLinks(false);
          }}
          variant="contained"
          color="primary"
          sx={{
           position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <CloseIcon sx={{
            color: "white"
          }} />
        </IconButton>
        {place?.navigationLinks.map((link, index) => (
          <Button
            key={index}
            
            onClick={() => {
              if (link.url != null) {
               window.open(link.url, "_blank");
               
              }
            }}
            variant="contained"
            color="primary"
            sx={{
              color: "white",
              boxShadow: "none",
             
              borderColor: "white",
              backgroundColor: "rgba(0,0,0,0.7)",
            }}
          >
            {link.name}
          </Button>
        ))}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
      }}
    >
      <Button
        startIcon={
          <Img
            src="https://static.urbnups.app/webapp/svg/urbnups_Icon_Navi_AhMUXyXYY.svg?updatedAt=1701432343400"
            width={20}
            height={20}
            alt="navigation icon"
          />
        }
        onClick={() => {
          showLocation();
        }}
        variant="contained"
        color="primary"
        sx={{
          color: "white",
          boxShadow: "none",
          marginTop: 1,
          marginLeft: 1,
          borderColor: "white",
          backgroundColor: "rgba(0,0,0,0.7)",
        }}
      >
        {title}
      </Button>
    </Box>
  );
};

export default PlaceMiniMapShowMapButton;
