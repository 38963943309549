import React, { useRef, useState, useEffect } from "react";
import { Box, IconButton, Typography, Link } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SingleRegionSlider from "../region/SingleRegionSlider";
import { useFetch } from "use-http";

const partnerId = window.urbnups.partnerId;
const language = window.urbnups.language;

const RegionsSlider = ({
  parentregionid = null,
  headline,
  subline,
  showMore = false,
  moreLabel = "more",
  moreLink = null,
  baseurl="",
  type=null
}) => {
  const sliderRef = useRef(null);
  const [isAtStart, setIsAtStart] = useState(true); // New state to track if at the start of the slider
  const [isAtEnd, setIsAtEnd] = useState(false); // New state to track if at the end of the slider

  const {
    loading,
    get,
    error,
    data = { region: null, subregions: [] },
  } = useFetch(
    `https://urbnups.com/widgets.api/region/${parentregionid}/subregions?lang=${language}&partnerId=${partnerId}`
    + (type!=null ? "&type=" + type : ""),
    [parentregionid, language, partnerId,type]
  );

  // Scroll left
  const scrollLeft = () => {
    if (sliderRef.current) {
      const slideWidth = sliderRef.current.offsetWidth;
      sliderRef.current.scrollBy({
        left: -slideWidth,
        behavior: "smooth",
      });
    }
  };

  // Scroll right
  const scrollRight = () => {
    if (sliderRef.current) {
      const slideWidth = sliderRef.current.offsetWidth;
      sliderRef.current.scrollBy({
        left: slideWidth,
        behavior: "smooth",
      });
    }
  };

  // Check if the slider is at the start or end to enable/disable buttons
  const handleScroll = () => {
    if (sliderRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;
      setIsAtStart(scrollLeft === 0); // Disable left button if at the start
      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth); // Disable right button if at the end
    }
  };

  // Attach the scroll event listener
  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (slider) {
        slider.removeEventListener("scroll", handleScroll);
      }
    };
  }, [data]);

  const onShowRegion = (region) => {

    if(baseurl!="")
    {
      if(baseurl.indexOf("?") > -1)
      window.location.href = baseurl + "&regionid=" + region.key;
      else
      window.location.href = baseurl + "?regionid=" + region.key;

      //push to history
      //window.history.pushState({ regionid: region.key }, "", window.location.href);
    }
    else{
      window.postMessage(
        {
          type: "urbnups-show-region",
          data: region,
        },
        "*"
      );
    }
    /*
    console.log("onShowRegion", region);
    window.postMessage(
      {
        type: "urbnups-show-region",
        data: region,
      },
      "*"
    );
    */
  };

  return (
    <Box className="urbnups-regionsliderContainer">
      {/* Header with headline and optional "More" button */}
      <Box sx={{ display: "flex", alignContent: "center", alignItems: "center" }}>
        {headline && (
          <Link href={moreLink}>
            <Typography
              variant="h3"
              sx={{
                color: "black",
                whiteSpace: "normal",
                fontWeight: 600,
                ml: { xs: 3, md: 0 },
                mr: { xs: 3, md: 0 },
              }}
            >
              {headline}
            </Typography>
          </Link>
        )}

        {showMore && (
          <Box sx={{ display: "flex", marginLeft: "auto", pr: 1 }}>
            <IconButton
              href={moreLink}
              sx={{
                backgroundColor: "rgba(255,255,255,1)",
                borderRadius: 30,
                pl: 2,
                color: "white",
                "&:hover": {
                  backgroundColor: "#FF8243",
                  color: "white",
                },
              }}
            >
              <Typography sx={{ color: "#1c1c1c" }} variant="subtitle2">
                {moreLabel}
              </Typography>
              <ArrowForwardIosIcon sx={{ color: "#1c1c1c", height: 15 }} />
            </IconButton>
          </Box>
        )}
      </Box>

      {/* Subline */}
      {subline != null && (
        <Typography
          variant="subtitle2"
          sx={{
            mt: 0.2,
            lineClamp: 1,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            overflow: "hidden",
            WebkitLineClamp: "2",
            ml: { xs: 3, md: 0 },
            mr: { xs: 3, md: 0 },
          }}
        >
          {subline}
        </Typography>
      )}

      {/* Slider with arrows */}
      <Box sx={{ position: "relative", width: "100%", overflow: "hidden", mt: 1 }}>
        {/* Left Arrow */}
        <Box
        className="urbnups-regions-slider-pagination urbnups-pagination-left"
        sx={{
          position: "absolute",
   
          left: 0,
          backgroundColor: "rgba(0,0,0,0.5)",
          opacity: isAtStart ? 0 : undefined, // Reduce opacity when disabled
          zIndex: 1,
        
          justifyContent: "center",
          display: "flex",

          
        }}
        onClick={scrollLeft}
        >
        <IconButton
          variant="contained"
          disabled={isAtStart} // Disable button if at the start
          sx={{
         
          }}
    
        >
          <ArrowBackIosNewIcon sx={{ color: "white", height: 15 }} />
        </IconButton>
        </Box>

        {/* Slider Content */}
        <Box
          ref={sliderRef}
          className="urbnups-regions-slider"
          sx={{
            display: "flex",
            flexDirection: "row",
            scrollSnapAlign: "center",
            scrollSnapType: "x mandatory",
            overflowX: "auto",
            overflowY: "hidden",
            whiteSpace: "nowrap",
            scrollBehavior: "smooth",
            WebkitOverflowScrolling: "touch",
            "&::-webkit-scrollbar": { display: "none" },
            width: "100%",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {!loading &&
            data?.subregions?.filter((region) => region?.thumbnail != null).map((region, index) => (
              <SingleRegionSlider
                key={region?.key}
                index={index}
                onShowRegion={onShowRegion}
                region={region}
              />
            ))}
        </Box>

        {/* Right Arrow */}
        <Box
         className="urbnups-regions-slider-pagination urbnups-pagination-right"
        sx={{
          position: "absolute",

          right: 0,
          backgroundColor: "rgba(0,0,0,0.5)",
          opacity: isAtEnd ? 0 : undefined, // Reduce opacity when disabled
          zIndex: 1,
        
          justifyContent: "center",
          display: "flex",

          
        }}
        onClick={scrollRight}
        >
          
        <IconButton
          disabled={isAtEnd} // Disable button if at the end
          sx={{
          
            zIndex: 1,
            opacity: isAtEnd ? 0.3 : 1, // Reduce opacity when disabled
          }}
       
        >
          <ArrowForwardIosIcon sx={{ color: "white", height: 15 }} />
        </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default RegionsSlider;