import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const POIQuestion = ({ question }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      onClick={handleExpandClick}
      sx={{
        transition: "height 2s ease", // Smooth height transition
        p: 2,
      
      }}
    >
      <Box sx={{ display: "flex",  maxWidth: "100%", justifyContent: "space-between", alignItems: "center",
          wordBreak: "break-word",
          flexWrap: "wrap",}}>
        <Typography variant="h5" sx={{
          whiteSpace:"pre-line"
        }} component="div">
          {question.question}
        </Typography>
        {question?.answer && (
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        )}
        {question?.answer == null && (
          <Button
            variant="outlined"
            color="primary"
            sx={{
              borderRadius: "30px",
              ml:2
            }}
            onClick={() => {}}
          >
            <SendIcon />
          </Button>
        )}
      </Box>

      {/* Show full or partial description based on expanded state */}
      {expanded && question?.answer && (
        <Typography
          sx={{
            pt: 1,
            textAlign: "left",
          }}
        >
          {question.answer}
        </Typography>
      )}
    </Box>
  );
};

export default POIQuestion;
