
import ChatWidget from "./components/chat/ChatWidget";


const ChatWidgetApp = ({
  partnerid, regionid, language,logo
}) => {

console.log('partnerId', partnerid)
  return (
       <ChatWidget
       
        partnerid={partnerid}
        regionid={regionid}
        language={language}
        logo={logo}

       />
  );
};

export default ChatWidgetApp;
