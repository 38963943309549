import { useFetch } from "use-http";

import MomentsSlider from "./components/slider/MomentsSlider";

export const RegionMomentsSliderApp = ({
  partnerid,
  regionid,
  headline,
  subline,
  morelabel,
  morelink,
}) => {
  const options = {}; // these options accept all native `fetch` options
  // the last argument below [] means it will fire onMount (GET by default)

  const onShowMoment = (moment) => {
    console.log("onShowMoment", moment);
    //emitCustomEvent('urbnups-show-moment', moment);
    window.postMessage(
      {
        type: "urbnups-show-moment",
        data: moment,
      },
      "*"
    );
  };

  const {
    loading,
    error,
    data = { moments: [] },
  } = useFetch(
    "https://urbnups.com/widgets.api/region/" +
      regionid +
      "/moments?lang=" +
      window.urbnups.language +
      "&partnerId=" +
      partnerid,
    options,
    [regionid, partnerid]
  );
  return (
    <>
      {error && "Error!"}
      {loading && "Loading..."}
      <MomentsSlider
        onShowMoment={onShowMoment}
        moments={data?.moments}
        headline={headline}
        subline={subline}
        morelabel={morelabel}
        morelink={morelink}
      />
    </>
  );
};
