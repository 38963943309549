import { Box, Typography } from "@mui/material";
import { Img } from "react-image";
import ResizeHelper from "../../../../utils/ResizeHelper";
const SingleDatahubTour = ({
  tour,
  withinFeed = false,
  onShowTour = () => {},
  scrollPosition,
}) => {
  return (
    <Box
      onClick={() => onShowTour(tour)}
      sx={{
        cursor: "pointer",
        overflow: "hidden",
        flexShrink: 0,
        position: "relative",
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        border: "1px solid #dcdfe4",

        flexBasis: {
          xs: 240,
          sm: 240,
          md: 300,
          lg: 300,
        },
      }}
      key={tour.key}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: {
            xs: 200,
            sm: 200,
            md: 200,
            lg: 200,
          },
        }}
      >
        {tour?.thumbnail != null && (
          <Img
            placeholder
            className="moment-thumbnail"
            src={ResizeHelper.resizeImage(tour?.thumbnail, 600)}
            alt={tour.name || ""}
            scrollPosition={scrollPosition}
            style={{
              width: "100%",

              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        )}
        {tour?.thumbnail == null && (
          <Box
            className="urbnups-poi-thumbnail-placeholder"
            style={{
              position: "relative",
              flex: 1,
              width: "100%",
              height: "100%",
              width: "100%",
              backgroundColor: "#1a1a1b",
            }}
          />
        )}
      </Box>
      <Box className="urbnups-singlepoislider-content">
        <Box className="urbnups-singlepoislider-content-text">
          <Typography component={"div"} className="urbnups-poi-slider-poi-name">
            {tour?.name}
          </Typography>
          <Typography
            component={"div"}
            className="urbnups-poi-slider-poi-teaser"
          >
            {tour?.teaser}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleDatahubTour;
