import { Box, Button, Link, Typography } from "@mui/material";
import Image from "rc-image";
import React from "react";
import { useTranslation } from "react-i18next";
import ResizeHelper from "../../utils/ResizeHelper";

const POIHighlights = ({ place }) => {
  const { t } = useTranslation();
  if (!place?.urbnupsMoments || place?.urbnupsMoments.length === 0) {
    return null;
  }


  return (
    <Box sx={{
      mt:2
    }}>
      <Typography
        variant="div"
        className="urbnups-poi-highlights-title"
        sx={{
          textAlign: "center",
          alignItems: "center",

          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
          pb: 2,
        }}
      >
        {t("place.highlights")}
      </Typography>
      <Box>
        {place.urbnupsMoments.map((moment, index) => (
          <Box key={index}>
            {moment.thumbnail && (
              <Image
                src={ResizeHelper.resizeImage(moment.thumbnail,900)}
                alt={moment.name || ""}
                style={{
                  width: "100%",
                  maxHeight: "300px",
                  objectFit: "cover",
                }}
                placeholder={
                  <Box
                    style={{
                      position: "relative",
                      flex: 1,
                      width: "100%",
                      height: "300px",
                      backgroundColor: "#1c1c1c",
                    }}
                  />

                }
              />
            )}
            <Box
              sx={{
                backgroundColor: "rgba(149,163,118,0.9)", // Light green color
                //add opacity
                //move -50 px to top
                position: "relative",
                marginTop: moment.thumbnail!=null?"-70px":"10px",
                padding: "40px",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  textAlign: "left",
                  color: "white",
                  mb: 2,
                  fontWeight: "bold",
                }}
              >
                {moment.name}
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: "white", // Subtle text color
                  lineHeight: 1.6,
                }}
              >
                {moment.description}
              </Typography>
              <Button variant="outlined" sx={{ mt:2,

                borderColor: "white",
                color: "white",
               }}
               href={
                "https://www.urbnups.com/u/moments/"+moment.key
               }
               target="_blank"
               LinkComponent={Link}
               >
                {t("place.urbnupsLink")}
              </Button> 
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default POIHighlights;
