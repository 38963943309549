import { Box } from "@mui/material";
import { Img } from "react-image";
import CopyrightTooltip from "../copyright/CopyrightTooltip";
import ResizeHelper from "../../utils/ResizeHelper";
const getImageCopyRight = (media) => {
  var copyright = "";
  if (media?.source != null && media?.source.length > 0) {
    copyright +=
      media?.source.trim() +
      " " +
      (media?.license != null ? "(" + media?.license.trim() + ")" : "");
  } else if (
    media?.googleMapsAuthorAttributions != null &&
    media?.googleMapsAuthorAttributions.length > 0
  ) {
    console.log(media?.googleMapsAuthorAttributions);
    copyright = media?.googleMapsAuthorAttributions
      .map((attribution, index) => {
        console.log(attribution);
        console.log("is google maps image");
        return attribution.displayName + " (Google Maps)";
      })
      .join(", ");
  }

  return copyright;
};

const MediaSlider = ({ media }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        scrollSnapAlign: "center",
        scrollSnapType: "x mandatory",

        overflowX: "auto",
        overflowY: "hidden",
        whiteSpace: "nowrap",
        scrollBehavior: "smooth",
        WebkitOverflowScrolling: "touch",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        msOverflowStyle: "none",
        scrollbarWidth: "2px",
       
        width: "100%",
        height: "100%",
        justifyContent: media.length > 1 ? "flex-start" : "center",
      }}
    >
      {media != null &&
        media.map((item, index) => (
          <Box
            sx={{
              display: "flex",
              scrollSnapAlign: "start",
              width: {
                xs: "calc(100%)",
                sm: "calc(100%)",
                md: "calc(50%)",
                lg: "calc(50%)",
                xl: "calc(50%)",
              },
           
              flexShrink: 0,
              position: "relative",
            }}
          >
            {item?.type === "image" && (
              <Box
                sx={{
                  position: "relative",
                  flex: 1,
                  aspectRatio: "1/1",
                  paddingLeft: {
                    xs: 0,
                    sm: 0,
                    md: index%2==0?0: "2px",
                    lg: index%2==0? 0:"2px",
                    xl: index%2==0? 0:"2px",
                  },
                  paddingRight: {
                    xs: 0,
                    sm: 0,
                    md: index%2==0? "2px":0,
                    lg: index%2==0? "2px":0,
                    xl: index%2==0? "2px":0,
                  },
                  
                  overflow: "hidden",
                }}
              >
                <Img
                  src={ResizeHelper.resizeImage(item?.url, 600)}
                  alt={item?.alt || ""}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </Box>
            )}
            {((item?.source != null && item?.source.length > 0) ||
              (item?.googleMapsAuthorAttributions != null &&
                item?.googleMapsAuthorAttributions.length > 0)) && (
              <Box
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  with: "100%",
                }}
              >
                <CopyrightTooltip text={getImageCopyRight(item)} />
              </Box>
            )}
          </Box>
        ))}
    </Box>
  );
};

export default MediaSlider;
