import { Box } from "@mui/material";
import React from "react";
import POIQuestionCTA from "./POIQuestionCTA";
import { useTranslation } from "react-i18next";

const POIQuestions = ({ place }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <POIQuestionCTA
        place={place}
        headline={t("place_chat_CTA.headline", { placeName: place?.name })}
        subline={t("place_chat_CTA.subline")}
        placeholder={""}
        questions={place?.questions}
      />
    </Box>
  );
};

export default POIQuestions;
