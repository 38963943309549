import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import SingleMoment from "./components/moment/SingleMoment";
import { useFetch } from "use-http";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
export const SingleMomentModal = ({}) => {
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);
  const [moment, setMoment] = useState(null);
  const partnerId = window.urbnups.partnerId;
  const language = window.urbnups.language;
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { get, loading, data, error } = useFetch(
    "https://urbnups.com/widgets.api/moment"
  );

  useEffect(() => {
    if (data) {
      console.log("data", data);
      setMoment(data);
    }
  }, [data]);
  const handlePopState = (event) => {
    //alert("handlePopState");
    setModalOpen(false);
  };
  useEffect(() => {
    window.addEventListener("message", (ev) => {
      const event = ev.data;

      if (event.type === "urbnups-show-moment") {
        setModalOpen(true);
        setMoment(event.data);
        const fetchUrl = `${event.data?.momentId || event?.data?.key}?lang=${
          window.urbnups.language
        }&partnerId=${partnerId}`;
        console.log("url ", fetchUrl);
        get(fetchUrl);
        window.addEventListener("popstate", handlePopState);
      }
      
    });

    return () => {
      window.removeEventListener("message", () => {});
    };

  }, []);

  const onClose = () => {
    setModalOpen(false);
    //setPlaceId(null);
  };
  return (
    <Dialog
      fullScreen={!isDesktop}
      maxWidth="md"
      open={modalOpen}
      onClose={onClose}
      aria-labelledby="urbnups Moment Modal"
      aria-describedby="Showing details of a urbnups Moment"
    >
      <DialogContent
        sx={{
          p: 0,
          width:isDesktop?"900px":"100%",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            flexDirection: "row",
            position: "absolute",
            top: 8,
            left: 8,
            zIndex: 100,
            //align vertical
          }}
        >
          {onClose && (
            <IconButton className="urbnups-backButton" onClick={onClose}>
              <ArrowBackIosNewIcon  />
            </IconButton>
          )}
        </Box>
        
        {loading && (
          <>
            <Box
              sx={{
                position: "absolute",
                left: 8,
                top: 8,
              }}
            >
              <IconButton sx={{}} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                padding: 10,
              }}
            >
            <CircularProgress className='urbnups-loadingindicator'/>
            </Box>
          </>
        )}

        {error && "Error!"}
        {!loading && !error && moment != null && (
          <SingleMoment onClose={onClose} moment={moment} language={language} />
        )}
      </DialogContent>
    </Dialog>
  );
};
