import { Box, Typography } from "@mui/material";
import TranslationHelper from "../../utils/TranslationHelper";
import MomentMiniMap from "../maps/MomentMiniMap";
import MediaSlider from "../mediaslider/MediaSlider";

import MomentEstablishment from "./MomentEstablishment";
import MomentLocation from "./MomentLocation";

const getMedia = (moment) => {
  var media = moment?.media || [];
  if (moment?.images) {
    media = [
      ...media,
      ...moment.images.map((image) => {
        return { ...image, type: "image" };
      }),
    ];
  }
  if (moment?.videos) {
    media = [
      ...media,
      ...moment.videos.map((video) => {
        return { ...video, type: "video" };
      }),
    ];
  }
  return media;
};

const SingleMoment = ({ moment, language = "de", onClose }) => {
  const momentTranslations = TranslationHelper.fetchMomentTranslations(
    moment,
    language
  );

  const media = getMedia(moment);
  return (
    <Box key={moment.key}>
      <Box
        sx={{
          position: "relative",
          flex: 1,
          width: "100%",

          overflow: "hidden",
        }}
      >
    

        <MediaSlider media={media} />
        <Box
          sx={{
            padding: 2,

          }}
        >
          <MomentLocation moment={moment} language={language} />
          <Box sx={{
            pb:1
          }}></Box>
          <Typography component={"h1"} sx={{mb:1}} variant="h4">
            {momentTranslations?.name}
          </Typography>
          <MomentEstablishment
            variant="subtitle2"
            moment={moment}
            language={language}
          />
          
          
          <Typography
            variant="body1"
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            {momentTranslations.description}
          </Typography>
        </Box>
        {moment?.location && <MomentMiniMap moment={moment} />}
      </Box>
    </Box>
  );
};

export default SingleMoment;
