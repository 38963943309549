import { Box, Button, Link, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ResizeHelper from "../../utils/ResizeHelper";
import Image from "rc-image";

function getNextTimeInterval(timeIntervals) {
  const now = new Date();

  // Sort the intervals by start time
  const sortedIntervals = timeIntervals
    .slice()
    .sort((a, b) => new Date(a.start) - new Date(b.start));

  for (const interval of sortedIntervals) {
    const start = new Date(interval.start);
    if (start > now) {
      return interval;
    }
  }

  // If no upcoming interval is found
  return null;
}
function formatEventDates(startDate, endDate) {
  // Create Date objects from the provided ISO strings
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Helper function to format a date into DD.MM.YYYY
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  // Format both dates
  const formattedStartDate = formatDate(start);
  const formattedEndDate = formatDate(end);

  // Return the formatted string
  return `${formattedStartDate} - ${formattedEndDate}`;
}

// 
function getNextTimeIntervalString(timeIntervals) {
  const interval = getNextTimeInterval(timeIntervals);
  if (interval) {
    //date should be formatted in dd.mm.yyyy format and if it ends on the same day, only the start date should be displayed
    const startDate = new Date(interval.start);
    const endDate = new Date(interval.end);
    if (startDate.getDate() === endDate.getDate()) {
     //format in dd.mm.yyy: hh:mm - hh:mm
      return `${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString()} - ${endDate.toLocaleTimeString()}`;
    }
    else
    {
      //format in dd.mm.yyy: hh:mm - dd.mm.yyy: hh:mm
      return `${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString()} - ${endDate.toLocaleDateString()} ${endDate.toLocaleTimeString()}`;
    }
  }
  return "";
}

const POIEvents = ({ place }) => {
  const { t } = useTranslation();
  if (!place?.events || place?.events.length === 0) {
    return null;
  }

  return (
    <Box sx={{
      mt:2
    }}>
      <Typography
        variant="div"
          className="urbnups-poi-events-title"
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          mb: 2,
          mt:2,
        }}
      >
       {t("place.events")}
      </Typography>
      <Box>
        {place.events.map((event, index) => (
          <Box key={index}>
            {event.thumbnail && (
              <Image
                src={ResizeHelper.resizeImage(event.thumbnail, 900)}
                alt={event.name || ""}
                style={{
                  width: "100%",
                  maxHeight: "300px",
                  objectFit: "cover",
                }}
                loading="eager"
                placeholder={
                  <Box
                    style={{
                      position: "relative",
                      flex: 1,
                      width: "100%",
                      height: "300px",
                      backgroundColor: "#1c1c1c",
                    }}
                  />

                }
              />
            )}
            
            <Box
              sx={{
                backgroundColor: "rgba(239,188,98,0.9)", // Light green color
                //add opacity
                //move -50 px to top
                position: "relative",
                marginTop: event.thumbnail!=null?"-70px":"0px",
                padding: "40px",
              }}
            >
              <Box
              sx={{
                backgroundColor: "#dc7942", // Light green color
                //add opacity
                //move -50 px to top
                position: "absolute",
                top: 0,
                marginTop: "-30px",
                height: "30px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
              >
              <Typography variant="body1" sx={{textAlign: "left", color: "white",  fontWeight: "bold"}}>
            {formatEventDates(event.startDate, event.endDate)}
              </Typography>
              </Box>
              <Typography
                variant="h3"
                className="urbnups-poi-event-name"
                sx={{
                  textAlign: "left",
                  color: "white",
                  mb: 2,
                  fontWeight: "bold",
                }}
              >
                {event.name}
              </Typography>

              <Typography
                variant="body1"
                     className="urbnups-poi-event-teaser"
                sx={{
                  color: "white", // Subtle text color
                  lineHeight: 1.6,
                }}
              >
                {event.teaser}
              </Typography>
                <Box sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                }}>
              <Button  sx={{
                backgroundColor: "#dc7942",
                color: "white",
                borderRadius: 0,
                padding: "10px 30px",
                "&:hover": {
                  backgroundColor: "#1c1c1c",
                },
                
              }}
              className="urbnups-poi-event-link"
              LinkComponent={Link}
              href={event.fullUrl}
              target="_blank"
              >
               {t("place.event_linkButton")}
                </Button></Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default POIEvents;
