import {
    Box,
    Button,
    Typography
} from "@mui/material";
const RegionPersonalizeCTA = ({
  questions = [],
  placeholder,
  headline,
  subline,
  place = null,
}) => {

    const onShowRegionPersonalize = () => {
    window.postMessage(
      {
        type: "urbnups-show-region-personalization",
        data: null,
      },
      "*"
    );
}

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 4,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "600px",
          backgroundColor: "#E3F5F9",
          padding: "20px",
          borderRadius: "15px",
          textAlign: "center",
        }}
      >
        <Typography
          
          sx={{ marginBottom: "20px", textAlign: "center" }}
        >
          {headline}
        </Typography>
        <Typography
          variant="h5"
          sx={{ marginBottom: "20px", textAlign: "center" }}
        >
          {subline}
        </Typography>
        <Button onClick={onShowRegionPersonalize} variant="contained" color="primary" sx={{ borderRadius: "30px" }}>
            Jetzt personalisieren
        </Button>

       
      </Box>
    </Box>
  );
};

export default RegionPersonalizeCTA;
