import { Box, Button, Typography } from "@mui/material";
import ResizeHelper from "../../utils/ResizeHelper";

const CategorySlider = ({
  categories = [],
  setCurrentCategoryId = {},
  currentCategoryId = null,
}) => {
  const onCategoryClick = (category) => {
    setCurrentCategoryId(category.id);
  };
  const isActiveCategory = (category) => {
    return currentCategoryId === category.id;
  };

  if (categories == null) return <></>;
  else
    return (
      <Box
        sx={{
          mt: 2,
          mb: 3,
          display: "flex",
          flexDirection: "row",
          width: "100%",
       
        }}
      >
       
        <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        scrollSnapAlign: "center",
        scrollSnapType: "x mandatory",

        overflowX: "auto",
        overflowY: "hidden",
        whiteSpace: "nowrap",
        scrollBehavior: "smooth",
        WebkitOverflowScrolling: "touch",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        msOverflowStyle: "none",
        scrollbarWidth: "2px",
       
        width: "100%",
        columnGap:1,
        justifyContent:"flex-start" 
      }}
    >
        {categories.map((category, index) => (
          <Button
          disableElevation={true}
            className={
              isActiveCategory(category)
                ? "urbnups-category-button-active"
                : "urbnups-category-button-inactive"
            }
            variant="contained"
            key={category?.id || index}
            startIcon={
              <img
                alt={category?.name}
                src={ResizeHelper.resizeImage(category?.icon, 128)}  
                width={15}
                height={15}
              />
            }
            // variant={selectedCategories.includes(category.id) ? 'contained' : 'text'}
            //color={selectedCategories.includes(category.id) ? 'secondary' : 'inherit'}
            //startIcon={<IconButton>{category.icon}</IconButton>}
            //onClick={() => onSelect(category.id)}
            onClick={() => onCategoryClick(category)}
            sx={{
              borderRadius: 10,
             
              pl: 2,
              pr: 2,
              pt: 0.75,
              pb: 0.75,
              textTransform: "none",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              minWidth: "auto",
              wordWrap: "none",
            }}
          >
            <Typography
              variant="body"
              sx={{
                fontWeight: isActiveCategory(category) ? 600 : 400,
              }}
            >
              {category.name}
            </Typography>
          </Button>
        ))}
       
         </Box>
      </Box>
    );
};

export default CategorySlider;
