import { ArrowForwardIos } from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SinglePOISlider from "../region/feed/components/SinglePOI";
import { trackWindowScroll } from "react-lazy-load-image-component";

const POISlider = ({
  pois,
  loading,
  onShowPOI,
  headline,
  subline,
  showMore = false,
  onMore,
  moreLabel,
  id,
  scrollPosition,
}) => {
  const sliderRef = useRef(null);
  const [isAtStart, setIsAtStart] = useState(true); // Track if slider is at start
  const [isAtEnd, setIsAtEnd] = useState(false); // Track if slider is at end

  useEffect(() => {
    const checkForOverflow = () => {
      if (sliderRef.current) {
        const { scrollWidth, clientWidth } = sliderRef.current;
        setIsAtEnd(sliderRef.current.scrollLeft + clientWidth >= scrollWidth);
        setIsAtStart(sliderRef.current.scrollLeft === 0);
      }
    };

    checkForOverflow();
    window.addEventListener("resize", checkForOverflow);
    return () => {
      window.removeEventListener("resize", checkForOverflow);
    };
  }, [pois]);

  const handleScroll = () => {
    if (sliderRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;
      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth); // Update end state
      setIsAtStart(scrollLeft === 0); // Update start state
    }
  };

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -sliderRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: sliderRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box
      sx={{
        mt: 1,
        position: "relative",
        width: "100%",
        maxWidth: "100vw",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{ display: "flex", alignContent: "center", alignItems: "center",

          justifyContent: "center",
         }}
      >
        {headline && (
          <Typography
            className="poi-slider-headline"
            sx={{
              whiteSpace: "pre-line",
     
            }}
          >
            {headline}
          </Typography>
        )}

        {showMore && (
          <Box sx={{ display: "flex", marginLeft: "auto", pr: 1 }}>
            <IconButton
              onClick={onMore}
              sx={{
                backgroundColor: "rgba(255,255,255,1)",
                borderRadius: 30,
                pl: 2,
                color: "white",
              }}
            >
              <Typography sx={{ color: "#1c1c1c" }} variant="subtitle2">
                {moreLabel}
              </Typography>
              <ArrowForwardIosIcon sx={{ color: "#1c1c1c", height: 15 }} />
            </IconButton>
          </Box>
        )}
      </Box>

      {subline != null && (
        <Typography
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            hyphens: "auto",
          }}
        >
          {subline}
        </Typography>
      )}

      {/* Left Arrow */}
      <IconButton
        disabled={isAtStart} // Disable button if at the start
        sx={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
          zIndex: 1,
          opacity: isAtStart ? 0.3 : 1, // Reduce opacity when disabled
        }}
        onClick={scrollLeft}
      >
        <ArrowBackIosNewIcon sx={{ color: "white", height: 15 }} />
      </IconButton>

      <Box
        ref={sliderRef}
        className="moments-slider"
        sx={{
          display: "flex",
          flexDirection: "row",
          overflowX: "auto",
          scrollSnapType: "x mandatory",
          scrollBehavior: "smooth",
          WebkitOverflowScrolling: "touch",
          "&::-webkit-scrollbar": { display: "none" },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          columnGap: 3,
          width: "100%",
          flexWrap: "nowrap",
        }}
        onScroll={handleScroll}
      >
        {!loading &&
          pois != null &&
          pois.map((poi, index) => (
            <SinglePOISlider
              scrollPosition={scrollPosition}
              onShowPOI={onShowPOI}
              key={poi?.googlePlaceId + "_" + id}
              poi={poi}
            />
          ))}
      </Box>

      {/* Right Arrow */}
      <IconButton
        disabled={isAtEnd} // Disable button if at the end
        sx={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          zIndex: 1,
          opacity: isAtEnd ? 0.3 : 1, // Reduce opacity when disabled
        }}
        onClick={scrollRight}
      >
        <ArrowForwardIosIcon sx={{ color: "white", height: 15 }} />
      </IconButton>
    </Box>
  );
};

export default trackWindowScroll(POISlider);
